#contact-head {
  margin: 2rem 2rem;
  background-color: #eaeaea;
  padding: 50px 0;
  text-align: center;
  border-radius: 10px;
}

.details {
  max-width: 800px;
  margin: 0 auto;
}

.details div {
  padding-bottom: 1rem;
}

.details h3 {
  margin-bottom: 5px;
}

.details p {
  padding-bottom: 1rem;
}

.details img {
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}

.details h2 {
  margin-bottom: 20px;
}

.details ul {
  list-style-type: none;
  padding: 0;
}

.details li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.details i {
  font-size: 20px;
  margin-right: 10px;
}

.details p {
  margin: 0;
}

.details a {
  text-decoration: none;
  color: #333; /* Change the link color */
}

@media (max-width: 850px) {
  #contact-head {
    padding: 30px 0;
  }

  .details {
    max-width: 90%;
    margin: 2rem 3rem;
  }

  .details img {
    height: 250px;
  }
}
